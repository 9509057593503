import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "./reducers";

export interface SocketIOState {
  sessionId: string;
}

const initialState: SocketIOState = {
  sessionId: "",
};

export const slice = createSlice({
  name: "socketIo",
  initialState,
  reducers: {
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSessionId } = slice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getSessionId = (state: RootState) => state.socketIo.sessionId;

export default slice.reducer;
